@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Hubot+Sans:ital,wdth,wght@0,75..125,200..900;1,75..125,200..900&family=Mona+Sans:ital,wght@0,200..900;1,200..900&display=swap');


:root {
  background-color: black;
}

body {
  margin: 0;
  font-family: "Mona Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

h1 {
  font-family: "Hubot Sans", sans-serif;
  font-stretch: 150%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}